<template lang='pug'>
	.loader--wrapper
		.loading
</template>

<script>

export default {
	name: 'Timer',
	props: {

	},
	data: () => ({

	}),
	computed: {

	},
	created () {

	},
	methods : {

	}
}
</script>

<style scoped lang='scss'>

</style>
